import { FC } from 'react';
import { ClientFormDefault } from '../../../models/ClientFormDefaults';
import { ClientForm } from '../../../models/ClientForm';
import { PeriodicReviewUtils } from '../../../utils/PeriodicReviewUtils';
import Tooltip from '../Tooltip';
import InfoIcon from '../icon/InfoIcon';
import { useTranslation } from 'react-i18next';

type Props = {
  source: ClientFormDefault | ClientForm;
};

const PeriodicReviewRecurrence: FC<Props> = (props) => {
  const { t, i18n } = useTranslation(['periodic-review', 'common']);

  const config = PeriodicReviewUtils.getConfig(props.source);
  if (!config) return null;

  const recurrence = PeriodicReviewUtils.getRecurrence(config, t);
  const recurrenceDueDate = PeriodicReviewUtils.getRecurrenceDueDate(config, t, i18n);
  const recurrenceEndText = PeriodicReviewUtils.getRecurrenceEndText(config, t);

  return (
    <Tooltip
      text={
        <div className="flex flex-col">
          <div>
            <span className="text-gray-300">{t('periodic-review:tooltip.due')}</span> <span>{recurrenceDueDate}</span>
          </div>
          <div>
            <span className="text-gray-300">{t('periodic-review:tooltip.recurrence')}</span> <span>{recurrence}</span>
          </div>
          {config.recurrenceFrequencyAmount > 0 && (
            <div>
              <span className="text-gray-300">{t('periodic-review:tooltip.recurrence-end')}</span> <span>{recurrenceEndText}</span>
            </div>
          )}
        </div>
      }
    >
      {(tooltip) => (
        <div className="flex items-center gap-2">
          {recurrence}
          <div {...tooltip}>
            <InfoIcon className="h-4 w-4" />
          </div>
        </div>
      )}
    </Tooltip>
  );
};

export default PeriodicReviewRecurrence;
